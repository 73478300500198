import { useState, useEffect, Dispatch } from "react";

export default function useStickyState<T>(
    defaultValue: any,
    key: string
): [any, Dispatch<any>] {
    const [value, setValue] = useState<T>(() => {
        const stickyValue = window.localStorage.getItem(key);
        return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
    });

    useEffect(() => {
        window.localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue];
}
