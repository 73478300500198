import "./App.css";
import React, { useRef, useState } from "react";
import DeleteIcon from "./DeleteIcon";
import AddIcon from "./AddIcon";
import EditIcon from "./EditIcon";
import useStickyState from "./useStickyState";

interface ItemInterface {
    name: string;
    hasFocus: boolean;
}

function App() {
    const [newItemText, setNewItemText] = useState("");
    const [newItemOpen, setNewItemOpen] = useState(false);
    const [items, setItems] = useStickyState<ItemInterface[]>([], "items");
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    let modalClasses =
        `absolute h-full w-screen top-0 bg-white dark:bg-gray-900 p-6 sm:p-10 flex flex-col justify-center items-center ` +
        (newItemOpen ? "left-0" : "-left-full");

    const handleAddItem = (e: any) => {
        e.stopPropagation();

        if (newItemText.length < 1) {
            setNewItemOpen(false);
            return;
        }

        const itemsTextSplit = newItemText.split("\n");

        const newItems = itemsTextSplit.map((item, key) => {
            return {
                name: item,
                hasFocus: key === 0,
            };
        });

        setItems(newItems);
        setNewItemOpen(false);
    };

    const toggleItem = (key: number) => {
        const newItems = items.map((item: ItemInterface, k: number) => {
            item.hasFocus = key === k;

            return item;
        });

        setItems(newItems);
    };

    const handleAddClick = (e: any) => {
        setNewItemOpen(!newItemOpen);

        if (textareaRef.current) {
            textareaRef.current.focus();
        }
    };

    return (
        <div className="App">
            <div className="h-full absolute left-0 top-0 w-screen overflow-hidden dark:bg-gray-900 dark:text-white">
                <button
                    className={`absolute right-0 top-0 text-4xl mt-5 mr-5 focus-visible:ring-2 focus-visible:ring-gray-800 focus:outline-none outline-none text-center ${
                        newItemOpen ? "hidden" : ""
                    }`}
                    title={newItemOpen ? "Close" : "Add new item"}
                    style={{ zIndex: 2 }}
                    onClick={handleAddClick}
                    id="addButton"
                >
                    {items.length === 0 ? (
                        <AddIcon className="w-8 fill-current" />
                    ) : (
                        <EditIcon className="w-8 fill-current" />
                    )}
                </button>
                <button
                    className={`absolute left-0 bottom-0 mb-5 ml-5 outline-none focus-visible:ring-2 focus-visible:ring-gray-800 focus:outline-none text-center ${
                        items.length === 0 ? "hidden" : ""
                    }`}
                    title={newItemOpen ? "Close" : "Add new item"}
                    style={{ zIndex: 2 }}
                    onClick={() => setItems([])}
                >
                    <DeleteIcon className="w-8 fill-current" />
                </button>

                {items.length === 0 ? (
                    <div className="text-center text-3xl h-full flex justify-center content-center items-center">
                        <p>What do you want to focus on?</p>
                    </div>
                ) : (
                    <ol className="h-full p-12 sm:p-20 flex justify-center content-center items-center flex-wrap">
                        {items.map((item: ItemInterface, key: number) => (
                            <li
                                className={`min-w-full my-4 text-center text-3xl text-black dark:text-white cursor-pointer transition-colors duration-300 ${
                                    item.hasFocus ? "" : "text-opacity-10"
                                }`}
                                key={key}
                                onClick={() => toggleItem(key)}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13) {
                                        toggleItem(key);
                                    }
                                }}
                            >
                                {item.name}
                            </li>
                        ))}
                    </ol>
                )}
                <div
                    tabIndex={newItemOpen ? 1 : -1}
                    className={modalClasses}
                    style={{
                        zIndex: 1,
                    }}
                    onClick={() => setNewItemOpen(false)}
                    role="dialog"
                    aria-labelledby="addItemDialog"
                >
                    <div className="flex flex-wrap justify-end relative w-full max-w-xl">
                        <div className="sr-only" id={"addItemDialog"}>
                            Add new items to your focus list
                        </div>
                        <textarea
                            className="p-4 w-full h-52 flex-grow border-2 rounded-none focus:outline-none focus:border-gray-700 dark:border-gray-700 dark:focus:border-gray-600 flex-shrink-0 text-2xl dark:bg-gray-800"
                            name="addItem"
                            id="addItem"
                            ref={textareaRef}
                            onChange={(e) => setNewItemText(e.target.value)}
                            value={newItemText}
                            onKeyDown={(e) => {
                                if (e.keyCode === 27) {
                                    setNewItemOpen(false);
                                }
                            }}
                            onClick={(e) => e.stopPropagation()}
                            placeholder={"Add each item on a new line"}
                        />
                        <a
                            href="#addButton"
                            onClick={handleAddItem}
                            className="px-4 py-3 bg-green-700 mt-2 dark:bg-green-600 dark:focus:bg-green-700 rounded-none focus:outline-none focus-visible:ring-2 focus-visible:ring-black dark:focus-visible:ring-white ring-2 ring-green-700 flex-shrink-0 flex-grow-0 text-white text-xl"
                        >
                            Done
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
