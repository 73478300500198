import React from "react";

const AddIcon = ({ width = "24px", height = "24px", ...rest }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={width}
            viewBox="0 0 24 24"
            width={height}
            fill="#000000"
            {...rest}
        >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
        </svg>
    );
};

export default AddIcon;
